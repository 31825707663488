import React from "react";

const RightIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16561 13.8054C7.04671 13.6837 6.98726 13.5239 6.98726 13.3262C6.98726 13.1284 7.04671 12.9687 7.16561 12.847L12.2038 7.68993H1.16879C0.975584 7.68993 0.815817 7.62528 0.68949 7.49597C0.563163 7.36667 0.5 7.20313 0.5 7.00537C0.5 6.80761 0.563163 6.64407 0.68949 6.51476C0.815817 6.38546 0.975584 6.32081 1.16879 6.32081H12.2038L7.16561 1.16376C7.04671 1.04206 6.98726 0.878523 6.98726 0.673154C6.98726 0.467785 7.04671 0.304251 7.16561 0.18255C7.2845 0.0608501 7.44055 0 7.63376 0C7.82696 0 7.98302 0.0608501 8.10191 0.18255L14.2994 6.52617C14.3737 6.60224 14.4257 6.6783 14.4554 6.75436C14.4851 6.83042 14.5 6.91409 14.5 7.00537C14.5 7.08143 14.4851 7.1613 14.4554 7.24497C14.4257 7.32863 14.3737 7.4085 14.2994 7.48456L8.10191 13.8282C7.98302 13.9499 7.82696 14.0069 7.63376 13.9993C7.44055 13.9917 7.2845 13.9271 7.16561 13.8054Z"
        fill={color}
      />
    </svg>
  );
};

export default RightIcon;
