import React from "react";

const QuestionTemplate = ({
  mapArray,
  checked,
  onChange,
  labelClass,
  radioClass,
  heading,
  name,
}) => {
  return (
    <>
      <h1 className="heading">{heading}</h1>

      <div className={`radio-container ${radioClass}`}>
        {mapArray.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <input
                type="radio"
                id={item}
                name={name}
                value={item}
                checked={checked === item}
                onChange={onChange}
              />
              <label
                htmlFor={item}
                className={`inactive ${labelClass === item ? "active" : null}`}
              >
                {item}
              </label>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default QuestionTemplate;
