import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function GenderMenu({ data, setData }) {
  const handleChange = (event) => {
    setData({ ...data, gender: event.target.value });
  };

  return (
    <FormControl
      sx={{ m: 1, minWidth: 120, background: "#f2f2f2", borderRadius: "24px" }}
      size="small"
    >
      {/* <InputLabel id="demo-select-small-label">Gender</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={data.gender}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        onChange={handleChange}
        sx={{
          border: "none",
          "& .MuiOutlinedInput-root": {
            border: "none",
            borderColor: "red",
            "&.Mui-focused fieldset": {
              border: "none",
              borderColor: "red",
            },
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#f2f2f2",
            },
          },
        }}
      >
        <MenuItem value="">
          <p style={{ color: "grey" }}>Select Gender</p>
        </MenuItem>
        <MenuItem value={"male"}>
          <p>Male</p>
        </MenuItem>
        <MenuItem value={"female"}>
          <p>Female</p>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
