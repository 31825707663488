import React, { useContext } from "react";
import Home from "./Home/Home";
import Race from "./Race/Race";
import BodyType from "./BodyType/BodyType";
import Looks from "./Looks/Looks";
import Personality from "./Personality/Personality";
import Matched from "../Matched/Matched";
import { StatesContext } from "../../context/StateContext";

const QuestionsComponent = () => {
  const { currentComponent, data, setData } = useContext(StatesContext);

  const components = {
    1: <Home data={data} setData={setData} />,
    2: <Race data={data} setData={setData} />,
    3: <BodyType data={data} setData={setData} />,
    4: <Looks data={data} setData={setData} />,
    5: <Personality />,
    6: <Matched />,
  };

  return (
    <div className="main-container">{components[currentComponent] || null}</div>
  );
};

export default QuestionsComponent;
