import React from "react";
import QuestionTemplate from "../QuestionTemplate/QuestionTemplate";
import appContent from "../../../content/content";

const Race = ({ data, setData }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setData({ ...data, race: value });
  };

  return (
    <>
      <QuestionTemplate
        mapArray={appContent.race}
        checked={data.race}
        onChange={handleChange}
        labelClass={data.race}
        heading={"Choose your celebrity race?"}
        name={"race"}
      />
    </>
  );
};

export default Race;
