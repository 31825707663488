import React, { useEffect, useContext } from "react";
import "./App.css";
import Bars from "./components/Bars/Bars";
import Button from "./components/Buttons/Button";
import logo from "./assets/logo.png";
import QuestionsComponent from "./components/QuestionsComponent/QuestionsComponent";
import Axios from "axios";
import { ToastProvider } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { StatesContext } from "./context/StateContext";

const App = () => {
  const {
    currentComponent,
    setCurrentComponent,
    data,
    setData,
    setResponse,
    baseUrl,
  } = useContext(StatesContext);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (id) {
      (async () => {
        try {
          setCurrentComponent(6);
          let response = await Axios.put(`${baseUrl}/api/user/${id}`, null, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });
          let userData = response.data;
          let { firstName, lastName, image, celebrityName, _id } = userData;
          setData({ ...data, firstName, lastName, img: image, userId: _id });
          setResponse(celebrityName);
        } catch (error) {
          console.log(error);
          navigate("/");
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ToastProvider>
      {currentComponent !== 1 && currentComponent !== 6 ? (
        <Bars currentComponent={currentComponent} />
      ) : null}

      <div className="App clCenter">
        {currentComponent !== 6 && <img src={logo} alt="logo" height={"16"} />}

        <QuestionsComponent />

        {currentComponent < 5 ? (
          <Button
            currentComponent={currentComponent}
            setCurrentComponent={setCurrentComponent}
            data={data}
          />
        ) : null}
      </div>
    </ToastProvider>
  );
};

export default App;
