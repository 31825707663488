import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";

import logo from "../../../../assets/logo.png";
import { DialogContainer } from "./EmailDialog.styled";

export default function EmailDialog({
  openDialog,
  handleCloseDialog,
  data,
  setData,
  baseUrl,
}) {
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleChange = (event) => {
    const { value } = event.target;
    setData({ ...data, email: value });
  };

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const postData = async () => {
    if (validateEmail(data.email)) {
      handleCloseDialog();
      try {
        const url = `${baseUrl}/api/user`;
        const formData = {
          image: data.imgBase64,
          firstName: data.firstName,
          lastName: data.lastName,
          age: data.age,
          gender: data.gender,
          email: data.email,
          celebrityName: data.celebrityName,
        };

        const response = await axios.post(url, formData, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        setData({ ...data, userId: response.data._id });
      } catch (error) {
        console.error(error);
      }
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContainer className="clCenter">
          <img src={logo} alt="logo" height={14} />

          <h4>Share email to view the result</h4>

          <div className="form">
            <input
              type="email"
              placeholder="hello@gmail.com"
              value={data.email}
              onChange={handleChange}
              className={!isValidEmail ? `invaild-input` : null}
            />
            {!isValidEmail && (
              <p className="invaild-email">Invalid email address</p>
            )}
            <button
              type="button"
              disabled={data.email === "" ? true : false}
              onClick={postData}
            >
              Continue
            </button>
          </div>
        </DialogContainer>
      </Dialog>
    </div>
  );
}
