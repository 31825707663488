import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  & .powerby {
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--secondary-color);
  }

  & .form {
    gap: 1.5rem;
  }

  & .pic {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background: var(--grey);
    margin-top: 2rem;
    gap: 0.5rem;
    cursor: pointer;
  }

  & .image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: contain;
  }
`;

export const FieldDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }

  input {
    width: 250px;
    padding: 8px 16px;
    border: none;
    outline: none;
    border-radius: 24px;
    background: var(--grey);
    font-size: 16px;
  }
`;

export const ImageDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  & .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    );
  }

  & .icon {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;
