import styled from "styled-components";

export const DialogContainer = styled.div`
  gap: 1rem;
  padding: 2rem;

  h4 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  & .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    input {
      background: #ffffff;
      border: 1px solid #d6bbfb;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 100%;
      padding: 0.3rem 0.5rem;
      font-size: 16px;
      outline: none;
      color: #7f56d9;
    }

    & .invaild-input {
      border: 1px solid #f67c7c;
      outline: 4px solid #fbdada;
    }

    & .invaild-email {
      color: #f67c7c;
      margin-top: -0.5rem;
    }

    button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      background: #be66ee;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.003);
      border-radius: 24px;
      border: none;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
    }

    button:disabled,
    button[disabled] {
      border: 1px solid #999999;
      background-color: #cccccc;
      cursor: no-drop;
    }
  }
`;
