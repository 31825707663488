import styled from "styled-components";

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0 2rem 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 40px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.003);
    border-radius: 24px;
    cursor: pointer;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    cursor: no-drop;
  }

  & .left-btn {
    background-color: var(--grey);
    width: 56px;
  }

  & .right-btn {
    background-color: var(--secondary-color);
    width: 120px;
    gap: 0.5rem;

    span {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
    }

    & .disabled {
      color: #2a2c3e;
    }
  }
`;
