import React from "react";
import QuestionTemplate from "../QuestionTemplate/QuestionTemplate";
import appContent from "../../../content/content";

const Looks = ({ data, setData }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setData({ ...data, looks: value });
  };

  return (
    <>
      <QuestionTemplate
        mapArray={appContent.looks}
        checked={data.looks}
        onChange={handleChange}
        labelClass={data.looks}
        heading={"Describe your celebrity looks?"}
        radioClass={"radio-gird"}
      />
    </>
  );
};

export default Looks;
