import React, { useContext, useEffect, useState } from "react";
import { Container, ImgContainer, MainContainer } from "./Matched.styled";

import logo from "../../assets/logo.png";
import LinkIcon from "../../assets/LinkIcon.svg";
import ShareIcon from "../../assets/ShareIcon.png";
import ImageAPI from "./ImageAPI";
import CircularProgress from "@mui/material/CircularProgress";
import { useToasts } from "react-toast-notifications";
import { StatesContext } from "../../context/StateContext";

const Matched = () => {
  const { data, response } = useContext(StatesContext);

  const { img, firstName, lastName } = data;
  const [searchName, setSearchName] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const { addToast } = useToasts();

  const encodedSearchTerm = encodeURIComponent(response);

  useEffect(() => {
    function removeNewlinesFromStart(str) {
      const regex = /^%0A%0A/;
      return str.replace(regex, "");
    }

    const result = removeNewlinesFromStart(encodedSearchTerm);
    setSearchName(result);
  }, [encodedSearchTerm]);

  return (
    <Container className="clCenter">
      <MainContainer className="clCenter">
        <div>
          <img src={logo} alt="logo" height={20} />
          <h3>It’s a match!</h3>
        </div>

        <ImgContainer>
          <div>
            {img ? (
              <img
                src={img.preview ? img.preview : img}
                className="img"
                alt={firstName}
              />
            ) : (
              <div className="img clCenter">
                <CircularProgress color="primary" />
              </div>
            )}
          </div>

          <div className="linkIcon">
            <img src={LinkIcon} alt="link" />
          </div>

          <div>
            <ImageAPI
              searchName={searchName}
              imageUrls={imageUrls}
              setImageUrls={setImageUrls}
            />
          </div>

          <h4>{firstName + " " + lastName}</h4>

          <div></div>

          <h4>{imageUrls.length !== 0 && response}</h4>
        </ImgContainer>

        <p className="link">
          Get Matched{" "}
          <a
            href="https://www.wivesofsolomon.com"
            target="_blank"
            rel="noreferrer"
          >
            wivesofsolomon.com
          </a>
        </p>
      </MainContainer>
      <button
        onClick={() => {
          let _id = data.userId;
          if (_id === "") {
            return;
          } else {
            let baseUrl = window.location.origin;
            if (navigator.share) {
              navigator
                .share({
                  title: "AICelebrityMatch",
                  text: "Check out who i matched with!",
                  url: `${baseUrl}?id=${_id}`,
                })
                .then(() => {})
                .catch((error) => console.log("Error sharing", error));
            } else {
              navigator.clipboard.writeText(`${baseUrl}?id=${_id}`);
              addToast("Linked Copied to Clipboard", {
                appearance: "success",
                autoDismiss: true,
              });
            }
          }
        }}
      >
        <img src={ShareIcon} alt="ShareIcon" />
        <span>Share</span>
      </button>

      <p className="join-link">
        Join our community and be early to our AI and Blockchain dating app.{" "}
        <a
          href="https://www.wivesofsolomon.com/community/"
          target="_blank"
          rel="noreferrer"
        >
          JOIN NOW
        </a>
      </p>
    </Container>
  );
};

export default Matched;
