import React, { useContext, useEffect, useState } from "react";

import EmailDialog from "./EmailDialog/EmailDialog";
import Button from "../../Buttons/Button";
import axios from "axios";
import QuestionTemplate from "../QuestionTemplate/QuestionTemplate";
import appContent from "../../../content/content";
import { StatesContext } from "../../../context/StateContext";

const Personality = () => {
  const {
    currentComponent,
    setCurrentComponent,
    data,
    setData,
    setResponse,
    baseUrl,
    prompt,
    setPrompt,
  } = useContext(StatesContext);

  const [openDialog, setOpenDialog] = useState(false);

  const HTTP = `${baseUrl}/chat`;

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentComponent(6);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setData({ ...data, personality: value });
  };

  const { gender, bodyType, personality, looks, race, firstName, lastName } =
    data;

  useEffect(() => {
    setPrompt(
      `Recommend the name of a ${
        gender === "male" ? "female" : "male"
      } celebrity based on the given categories: Age, Race, Body Size, Looks, and Personality. Provide only the name of the celebrity, without any additional information.
      
      Example:
      Race: ${race}
      Body Size: ${bodyType}
      Looks: ${looks}
      Personality: ${personality}`
    );
  }, [
    gender,
    bodyType,
    personality,
    looks,
    race,
    firstName,
    lastName,
    setPrompt,
    prompt,
  ]);

  const handleMatch = () => {
    setOpenDialog(true);

    axios
      .post(
        `${HTTP}`,
        { prompt },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((res) => {
        setResponse(res.data);
        setData({ ...data, celebrityName: res.data.toString().trim() });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <QuestionTemplate
        mapArray={appContent.personality}
        checked={data.personality}
        onChange={handleChange}
        labelClass={data.personality}
        heading={"Describe your celebrity personality?"}
        radioClass={"radio-gird"}
      />

      <Button
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        data={data}
        setOpenDialog={setOpenDialog}
        onClick={handleMatch}
      />

      <EmailDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        data={data}
        setData={setData}
        baseUrl={baseUrl}
      />
    </>
  );
};

export default Personality;
