import React from "react";
import QuestionTemplate from "../QuestionTemplate/QuestionTemplate";
import appContent from "../../../content/content";

const BodyType = ({ data, setData }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setData({ ...data, bodyType: value });
  };

  return (
    <>
      <QuestionTemplate
        mapArray={appContent.bodyType}
        checked={data.bodyType}
        onChange={handleChange}
        labelClass={data.bodyType}
        heading={"Choose your celebrity body types?"}
      />
    </>
  );
};

export default BodyType;
