import styled from "styled-components";

export const BarContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;

  & .bar {
    background: #f2f2f2;
    border-radius: 8px;
    height: 5px;
    width: 100%;
  }

  & .activeBar {
    background: var(--secondary-color);
  }
`;
