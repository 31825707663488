import styled from "styled-components";

export const Container = styled.div`
  gap: 2rem;
  margin-bottom: 2rem;

  h3 {
    font-family: "Lobster", cursive;
    text-align: center;
    color: var(--secondary-color);
    font-weight: 400;
    font-size: 32px;
    margin-top: 0.5rem;

    @media screen and (max-width: 640px) {
      font-size: 28px;
    }
  }

  & .link {
    a {
      text-decoration: none;
      color: dodgerblue;
    }
  }

  button {
    cursor: pointer;
    width: 140px;
    height: 40px;
    background: var(--secondary-color);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.003);
    border-radius: 24px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    span {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
    }
  }

  & .join-link {
    text-align: center;

    a {
      color: dodgerblue;
      text-decoration: none;
      font-weight: 500;
      white-space: nowrap;
    }
  }
`;

export const MainContainer = styled.div`
  background: var(--grey);
  width: fit-content;
  border-radius: 24px;
  gap: 2rem;
  padding: 1.5rem 6rem;

  @media screen and (max-width: 992px) {
    padding: 1.5rem 4rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
    /* width: 95vw; */
  }

  @media screen and (max-width: 500px) {
    width: 95vw;
  }
`;

export const ImgContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 4rem;
  row-gap: 1rem;
  margin: 1rem 0;

  & .linkIcon {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }

  & .img {
    border-radius: 100%;
    width: 400px;
    height: 400px;
    background-color: white;
    object-fit: contain;
    object-position: top center;

    /* img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: contain;
      object-position: top center;
    } */

    @media screen and (max-width: 992px) {
      width: 200px;
      height: 200px;
    }

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 120px;
      height: 120px;
    }
  }

  h4 {
    font-size: 24px;
    text-align: center;
    flex-grow: 1;
    line-height: 1.8rem;

    @media screen and (max-width: 640px) {
      font-size: 18px;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
`;
