import React, { useEffect, useState } from "react";

import { ButtonDiv } from "./Button.styled";

import RightIcon from "../../assets/RightIcon";
import LeftIcon from "../../assets/LeftIcon.svg";

const Button = ({
  currentComponent,
  setCurrentComponent,
  data,
  setOpenDialog,
  onClick,
  type,
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const {
      img,
      firstName,
      lastName,
      age,
      gender,
      race,
      bodyType,
      looks,
      personality,
    } = data;

    if (currentComponent === 1) {
      // start of home validation
      const homeValidation =
        img !== null &&
        firstName !== "" &&
        lastName !== "" &&
        age !== "" &&
        gender !== "";

      setDisabled(!homeValidation);
    }
    // end of home validation

    if (currentComponent === 2) {
      setDisabled(race === "");
    }

    if (currentComponent === 3) {
      setDisabled(bodyType === "");
    }

    if (currentComponent === 4) {
      setDisabled(looks === "");
    }

    if (currentComponent === 5) {
      setDisabled(personality === "");
    }
  }, [currentComponent, data, setOpenDialog]);

  const handleNext = () => {
    if (currentComponent < 6) {
      setCurrentComponent(currentComponent + 1);
    }
  };

  const handleBack = () => {
    if (currentComponent > 1) {
      setCurrentComponent(currentComponent - 1);
    }
  };

  return (
    <ButtonDiv>
      {currentComponent !== 1 && (
        <button
          onClick={handleBack}
          disabled={currentComponent === 1}
          className="left-btn"
          type="button"
        >
          <img src={LeftIcon} alt="LeftIcon" />
        </button>
      )}
      <button
        onClick={onClick ? onClick : handleNext}
        disabled={disabled}
        type={type ? type : "button"}
        className="right-btn"
      >
        <span className={`${disabled && "disabled"}`}>
          {currentComponent !== 5 ? "Next" : "Match me"}
        </span>{" "}
        {currentComponent !== 5 && (
          <RightIcon color={disabled ? "#2a2c3e" : "#ffffff"} />
        )}
      </button>
    </ButtonDiv>
  );
};

export default Button;
