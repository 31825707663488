import React, { useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { StatesContext } from "../../context/StateContext";

const ImageAPI = ({ searchName, imageUrls, setImageUrls }) => {
  const { data, setData, setResponse, baseUrl, prompt } =
    useContext(StatesContext);

  const HTTP = `${baseUrl}/chat`;

  // calling the chatGPT api
  const chatGPI = () => {
    axios
      .post(
        `${HTTP}`,
        { prompt },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((res) => {
        setResponse(res.data);
        setData({ ...data, celebrityName: res.data.toString().trim() });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // calling the image api
  const getImages = async () => {
    const apiKey = process.env.REACT_APP_TMDB_API;
    let searchUrl = "";
    if (searchName !== "") {
      searchUrl = `https://api.themoviedb.org/3/search/person?api_key=${apiKey}&query=${searchName}`;
      try {
        const response = await fetch(searchUrl);

        if (!response.ok) {
          throw new Error("Failed to search for image.");
        }

        const data = await response.json();
        if (data.results.length === 0) {
          throw new Error("No results found for image.");
        }

        const personId = data.results[0].id;
        const imagesUrl = `https://api.themoviedb.org/3/person/${personId}/images?api_key=${apiKey}`;

        const imagesResponse = await fetch(imagesUrl);
        if (!imagesResponse.ok) {
          throw new Error("Failed to fetch images.");
        }

        const imagesData = await imagesResponse.json();
        const urls = imagesData.profiles.map((image) => image.file_path);
        setImageUrls(urls);
      } catch (error) {
        console.error(error);
        chatGPI();
      }
    }
  };

  useEffect(() => {
    getImages();
    // eslint-disable-next-line
  }, [searchName]);

  return (
    <>
      {imageUrls.length !== 0 ? (
        <img
          src={`https://image.tmdb.org/t/p/original${imageUrls[0]}`}
          alt="celebrity"
          className="img"
        />
      ) : (
        <div className="img clCenter">
          <CircularProgress color="primary" />
        </div>
      )}
    </>
  );
};

export default ImageAPI;
