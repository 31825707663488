import React from "react";
import { BarContainer } from "./Bar.styled";

const Bars = ({ currentComponent }) => {
  return (
    <BarContainer>
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <React.Fragment key={item}>
            <div
              className={`bar ${currentComponent > item ? "activeBar" : null}`}
            />
          </React.Fragment>
        );
      })}
    </BarContainer>
  );
};

export default Bars;
