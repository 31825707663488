const appContent = {
  data: {
    imgBase64: "",
    img: null,
    firstName: "",
    lastName: "",
    age: "",
    gender: "",
    race: "",
    bodyType: "",
    looks: "",
    personality: "",
    email: "",
    celebrityName: "",
    userId: "",
  },
  race: ["Black", "White", "Asian", "Mixed"],
  bodyType: ["Slim", "Fit", "Thick", "Plus size"],
  looks: [
    "Handsome",
    "Hot",
    "Rugged",
    "Sexy",
    "Pretty",
    "Beautiful",
    "Sophisticated",
    "Natural size",
    "Fashionable",
    "Exotic",
  ],
  personality: [
    "Charismatic",
    "Humble",
    "Outgoing",
    "Bold",
    "Creative",
    "Fearless",
    "Ambitious",
    "Intellectual",
    "Humanitarian",
    "Unique",
  ],
};

export default appContent;
