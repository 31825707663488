import React, { useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import GenderMenu from "./GenderMenu";

import { Container, FieldDiv, ImageDiv } from "./Home.styled";

import CameraIcon from "../../../assets/CameraIcon";
import { useToasts } from "react-toast-notifications";
const Home = ({ data, setData }) => {
  const inputRef = useRef(null);
  const { addToast } = useToasts();

  useEffect(() => {
    const value = inputRef.current.value;

    if (value.includes("-") || value.includes("+") || value.includes("e")) {
      setData({ ...data, age: "" });
    }
  }, [data, setData]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },

    onDrop: (acceptedFiles) => {
      const MAX_FILE_SIZE = 5120; // 1MB

      if (acceptedFiles.length > 0) {
        let file = acceptedFiles[0];
        let fileSize = file.size / 1024; //1024 is 1MB
        if (fileSize > MAX_FILE_SIZE) {
          addToast(`Max file size is 5MB`, {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        const imageArray = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        (async () => {
          const base64 = await convertToBase64(file);
          if (fileSize > MAX_FILE_SIZE) {
            addToast(`Max file size is 1MB`, {
              appearance: "error",
              autoDismiss: true,
            });
            return;
          }
          setData({ ...data, img: imageArray, imgBase64: base64 });
        })();
      }
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Container>
      <h1 className="heading">
        Use AI to find your celebrity love match. Answer 5 questions and let AI
        do the rest.
      </h1>
      <p className="powerby">Powered by: Wives Of Solomon</p>

      <div className="clCenter form">
        {/* uploading image */}
        <div
          {...getRootProps({ className: "dropzone" })}
          className="pic clCenter"
        >
          <input {...getInputProps()} multiple />

          {data.img === null ? (
            <>
              <CameraIcon color={"#2A2C3E"} />
              <p>Upload Photo</p>
            </>
          ) : (
            <ImageDiv>
              <div className="icon">
                <CameraIcon color={"#f2f2f2"} />
              </div>
              <img src={data.img.preview} alt="upload" className="image" />
              <div className="gradient-overlay" />
            </ImageDiv>
          )}
        </div>
        {/* end of uploading image */}

        <FieldDiv>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={data.firstName}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={data.lastName}
            name="lastName"
            onChange={handleChange}
          />
          <input
            type="number"
            name="age"
            placeholder="Age"
            value={data.age}
            onChange={handleChange}
            min={1}
            ref={inputRef}
          />
          <GenderMenu data={data} setData={setData} />
        </FieldDiv>
      </div>
    </Container>
  );
};

export default Home;
