import React from "react";

const CameraIcon = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.45 24.6667C16.0611 24.6667 17.4111 24.1222 18.5 23.0333C19.5889 21.9444 20.1333 20.5944 20.1333 18.9833C20.1333 17.3722 19.5889 16.0278 18.5 14.95C17.4111 13.8722 16.0611 13.3333 14.45 13.3333C12.8389 13.3333 11.4944 13.8722 10.4167 14.95C9.33889 16.0278 8.8 17.3722 8.8 18.9833C8.8 20.5944 9.33889 21.9444 10.4167 23.0333C11.4944 24.1222 12.8389 24.6667 14.45 24.6667ZM3.13333 29.5333C2.6 29.5333 2.13333 29.3333 1.73333 28.9333C1.33333 28.5333 1.13333 28.0667 1.13333 27.5333V10.4333C1.13333 9.92222 1.33333 9.46111 1.73333 9.05C2.13333 8.63889 2.6 8.43333 3.13333 8.43333H8.03333L9.86666 6.23333C10.0444 5.98889 10.2667 5.81111 10.5333 5.7C10.8 5.58889 11.0889 5.53333 11.4 5.53333H18.8C19.0833 5.53333 19.3208 5.62917 19.5125 5.82083C19.7042 6.0125 19.8 6.25 19.8 6.53333V10.4333H3.13333V27.5333H25.8V14.4333H26.8C26.9333 14.4333 27.0609 14.4588 27.1827 14.5098C27.3045 14.5608 27.4101 14.633 27.4994 14.7265C27.5887 14.8199 27.6611 14.9283 27.7167 15.0514C27.7722 15.1746 27.8 15.3019 27.8 15.4333V27.5333C27.8 28.0667 27.5944 28.5333 27.1833 28.9333C26.7722 29.3333 26.3111 29.5333 25.8 29.5333H3.13333ZM25.8 8.43333H23.9333C23.65 8.43333 23.4125 8.33694 23.2208 8.14417C23.0292 7.95137 22.9333 7.71248 22.9333 7.4275C22.9333 7.1425 23.0292 6.90556 23.2208 6.71667C23.4125 6.52778 23.65 6.43333 23.9333 6.43333H25.8V4.53333C25.8 4.25 25.8964 4.0125 26.0892 3.82083C26.282 3.62917 26.5209 3.53333 26.8058 3.53333C27.0908 3.53333 27.3278 3.62917 27.5167 3.82083C27.7056 4.0125 27.8 4.25 27.8 4.53333V6.43333H29.7C29.9833 6.43333 30.2208 6.52972 30.4125 6.7225C30.6042 6.9153 30.7 7.15419 30.7 7.43917C30.7 7.72417 30.6042 7.96111 30.4125 8.15C30.2208 8.33889 29.9833 8.43333 29.7 8.43333H27.8V10.3C27.8 10.5833 27.7036 10.8208 27.5108 11.0125C27.318 11.2042 27.0791 11.3 26.7942 11.3C26.5092 11.3 26.2722 11.2042 26.0833 11.0125C25.8944 10.8208 25.8 10.5833 25.8 10.3V8.43333Z"
        fill={color}
      />
    </svg>
  );
};

export default CameraIcon;
