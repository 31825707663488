import React, { createContext, useState } from "react";

import appContent from "../content/content";

// Create a new context
export const StatesContext = createContext();

// Create a context provider component
const StateProvider = ({ children }) => {
  const [currentComponent, setCurrentComponent] = useState(1);
  const [data, setData] = useState(appContent.data);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
// 
  const baseUrl = `${
    window.location.protocol === "https:" ? "https:" : "http:"
  }//www.solomonsclub.com:${
    window.location.protocol === "https:" ? 8081 : 8080
  }`;

  return (
    <StatesContext.Provider
      value={{
        currentComponent,
        setCurrentComponent,
        data,
        setData,
        response,
        setResponse,
        baseUrl,
        prompt,
        setPrompt,
      }}
    >
      {children}
    </StatesContext.Provider>
  );
};

export default StateProvider;
